.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 2rem;
}

.title {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.date {
    margin-bottom: 1rem;
    color: #777;
}

.text {
    margin-bottom: 1rem;
}

.buttonGroup {
    display: flex;
    gap: 1rem;
}

button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #eee;
}


.entry-card img {
    max-width: 50%;
    max-height: 50%; 
    display: inline-block; /* makes images go side-by-side if they fit */
  }