.subscription-status-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #090931;
    color: white;
}

.fullscreen-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.tier-card {
    text-align: center;
    padding: 1em;
    margin: 1em;
    border-radius: 10px;
    background-color: #68449c;
    border: 3px solid #FE78FD;
}

.back-to-dashboard {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
}
