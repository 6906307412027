.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 5vh;
    padding: 2rem;
}

.title {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.inputGroup {
    margin-bottom: 1rem;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
}

.label {
    margin-bottom: 0.5rem;
}

.input, .textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.textarea {
    resize: vertical;
    min-height: 100px;
}

.button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #eee;
}

.react-quill {
    max-height: 300px; /* Adjust as necessary */
    overflow-y: auto; /* Allows for scrolling if the content exceeds the editor's max height */
}

.quill-editor {
    height: 400px;
}

.imgStyle {
    max-width: 50% !important;
    max-height: 50% !important;
    display: inline-block !important;
    margin: 10px 5px !important;
    vertical-align: middle !important;
}

.ql-editor img {
    max-width: 50% !important;
    max-height: 50% !important;
    display: inline-block !important;
    margin: 10px 5px !important;
    vertical-align: middle !important;
}

