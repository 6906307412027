.change-log-page {
  padding: 20px;
  align-items: center;
}

.change-log-page h2 {
  color: #FE78FD !important;
  text-align: center;
}

.entries-list {
  display: flex;
  flex-direction: column;
  padding: 20px; 
}

.entry-card {
  padding: 15px;
  margin: 10px 0;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
  background-color: #68449c;
  border-radius: 8px; 
}

.entry-card p {
  margin: 0;
  padding: 0;
  color: White !important;
}

/* Styles for date-time and entry-text rectangles */
.entry-card .entry-text {
  position: relative;  /* Setting context for the absolute positioned child */
  background-color: transparent; /* No fill color */
  border: 2px solid #FE78FD; /* Using the color of ChangeLog text for the border */
  border-radius: 4px; /* Giving a bit of rounded corners */
  padding: 6px; /* Adjusting padding to account for the border width */
  margin: 5px 0; /* Margin to separate from other elements */
  color: White; /* Text color */
}
.date-time {  
  font-size: 0.85em; /* Reducing the font size */
  margin-bottom: 6px; /* Space between date-time and title */
  border: none; /* Removing the border */
}

.entry-card-link {
  text-decoration: none;
}

.entry-card-link:hover .entry-card {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: #007BFF;
  transform: scale(1.02); /* slightly increase size */
  transition: all 0.3s ease; /* smooth transition for all changes */
}

body .ql-container .ql-size-small {
  font-size: 0.75em;
}

body .ql-container .ql-size-huge {
  font-size: 2em;
}

.entry-card img {
  max-width: 50%; /* you can adjust this value */
  max-height: 50%; /* you can adjust this value */
  display: inline-block; /* makes images go side-by-side if they fit */
}