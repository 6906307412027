.success-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 30px;
    text-align: center;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    line-height: 1.6;
  }
  
  .h1 {
    color: #2c3e50;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  .p {
    color: #7f8c8d;
    font-size: 16px;
    margin-bottom: 30px;
  }
  
  .details {
    text-align: left;
    margin-top: 20px;
    padding: 15px;
    border-top: 3px solid #ecf0f1;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .details div {
    margin-bottom: 12px;
  }
  
  .details strong {
    color: #34495e;
    display: inline-block;
    min-width: 150px;
    font-weight: 600;
  }
  
 .button {
    margin-top: 30px;
    padding: 12px 25px;
    font-size: 16px;
    color: #ff0000;
    background-color: #f0f0f0;
    border: 1px solid #f0f0ff;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #757474;
  }
  
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .error {
    color: #e74c3c;
    font-weight: 600;
  }
  