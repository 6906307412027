.App {
    margin-left: 20px;
    margin-right: 20px;
  }

.ip-address {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 10%;
}

.getstarted {
    font-size: 14px;
}

.rounded-button {
    border: 1px solid #0588b4;
    border-radius: 12px;
  }
.custom-link {
    color: #FE78FD !important;
    transition: color 0.3s ease-in-out;
    text-decoration: none;
}

.custom-link:focus, .custom-link:hover {
    color: white !important;
    transition: color 0.3s ease-in-out;
    text-decoration: none;
}
